<template>
    <div>
        <div class="dot-typing">
        </div>
        <span class="dot-text">{{loaderText}}</span>
    </div>
</template>
<script>
export default {
    name: 'dotloader',
    props: {
        loaderText: {
            type: String,
            default: 'Loading'
        }
    }
}
</script>