import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import axios from 'axios';
import validator from 'validator';
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuex);
Vue.use(Vuetify);

let apiSubDomain = (process.env.NODE_ENV && process.env.NODE_ENV === 'production') ? 'https://api.integrateiq.com' : 'http://localhost:58745';

const http = axios.create({
    baseURL: `${apiSubDomain}/api/`
});

const vuexLocal = new VuexPersistence({
    key: 'appiq',
    storage: window.sessionStorage
});

const store = new Vuex.Store({
    plugins: [vuexLocal.plugin],
    state: {
        registration: {
            firstName: "",
            lastName: "",
            email: "",
            phone: ""
        },
        optEmailOrPhone: "",
        token: localStorage.getItem('token') || '',
    },
    mutations: {
        setEmailPhone(state, emailOrPhone) {
            state.optEmailOrPhone = emailOrPhone;
        },
        setRegFirstName(state, firstName) {
            state.registration.firstName = firstName;
        },
        setRegLastName(state, lastName) {
            state.registration.lastName = lastName;
        },
        setRegEmail(state, email) {
            state.registration.email = email;
        },
        setRegPhone(state, phone) {
            state.registration.phone = phone;
        }
    },
    actions: {
        async registerUser({ state }) {

            let response = await http.post('Account', {
                firstName: state.registration.firstName,
                lastName: state.registration.lastName,
                email: state.registration.email,
                phone: state.registration.phone
            });
            return response;
        },
        async requestAuthCode({ state }) {
            let request = {};

            if (validator.isEmail(state.optEmailOrPhone)) {
                request = {
                    authenticationType: "Email",
                    value: state.optEmailOrPhone
                }
            } else {
                request = {
                    authenticationType: "Phone",
                    value: state.optEmailOrPhone
                }
            }

            let response = await http.post('Authentication', request);
            return response;
        },
        async requestAuthToken({ state }, { authCode }) {
            let response = await http.get('Authentication/' + authCode);
            console.log(state);
            return response;
        },
        async getCompanyIFrameData({state}, companyId){
            let response = await http.get(`RollingRock/GetCompanyFrameData?companyId=${companyId}`);
            console.log(state);
            return response.data;
        },
        async getCompanyPrice({state},{selectedProduct,quantity,priceLevel}){
            let response = await http.post('RollingRock/GetCompanyPrice', {
                productId: selectedProduct,
                quantity: quantity,
                priceLevel: priceLevel
            });
            console.log(state);
            return response.data;
        },
        async getProducts(){
            let response = await http.get('RollingRock/GetItems');
            return response.data;
        },
        async getProductInventory({state},selectedProduct){
            console.log(state);
            let response = await http.get(`RollingRock/GetItemInventory/${selectedProduct}`);
            return response.data;
        }
    },
    getters: {
        isLoggedIn: state => !!state.token,
        getEmailOrPhone: state => state.optEmailOrPhone,
        getRegistrationData: state => state.registration,
        getAuthToken: state => state.token
    }
});

export default store;