import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

/*Components*/
import Registration from '../components/Registration';
import Login from '../components/Login';
import CompanyIFrame from '../pages/rollingrock/CompanyIFrame';
import DealIFrame from '../pages/rollingrock/DealIFrame';

const routes = [
    {
        path: "/registration",
        name: "registration",
        component: Registration,
    },
    {
        path: "/login",
        name: "login",
        component: Login
    },
    {
        path: "/f/rr/company",
        name: "rr company",
        component: CompanyIFrame
    },
    {
        path: "/f/rr/deal",
        name: "rr deal",
        component: DealIFrame
    }
];

const router = new VueRouter({
    routes,
    mode: 'history',
    base: process.env.BASE_URL
});

export default router;

