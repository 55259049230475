<template>
<v-app id="registration">
    <v-container>
        <h1 class="text-center">Create your account</h1>

        <form>
            <v-text-field v-model="firstName" :error-messages="firstNameErrors" label="FirstName" required></v-text-field>
            <v-text-field v-model="lastName" :error-messages="LastNameErrors" label="LastName" required></v-text-field>
            <v-text-field v-model="email" :error-messages="emailErrors" label="E-mail" required></v-text-field>
            <v-text-field v-model="phone" :error-messages="phoneErrors" label="Mobile Number" placeholder="(xxx) xxx xxxx" v-mask="'(###) ###-####'" aria-describedby="basic-addon1" required></v-text-field>
        </form>

        <v-btn color="primary" class="mr-4" @click="register">Create</v-btn>
    </v-container>
</v-app>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';

export default {
    validations: {
        firstName: {
            required
        },
        lastName: {
            required
        },
        email: {
            required,
            email
        },
        phone: {
            required
        }
    },
    computed: {
        firstName: {
            get() {
                var registration = this.$store.getters.getRegistrationData;
                return registration.firstName;
            },
            set(value) {
                this.$store.commit('setRegFirstName', value);
            }
        },
        lastName: {
            get() {
                var registration = this.$store.getters.getRegistrationData;
                return registration.lastName;
            },
            set(value) {
                this.$store.commit('setRegLastName', value);
            }
        },
        email: {
            get() {
                var registration = this.$store.getters.getRegistrationData;
                return registration.email;
            },
            set(value) {
                this.$store.commit('setRegEmail', value);
            }
        },
        phone: {
            get() {
                var registration = this.$store.getters.getRegistrationData;
                return registration.phone;
            },
            set(value) {
                this.$store.commit('setRegPhone', value);
            }
        },
        firstNameErrors() {
            const errors = []
            if (!this.$v.firstName.$dirty) return errors;
            !this.$v.firstName.required && errors.push('First Name is required.');
            return errors;
        },
        LastNameErrors() {
            const errors = []
            if (!this.$v.lastName.$dirty) return errors;
            !this.$v.lastName.required && errors.push('Last Name is required.');
            return errors;
        },
        emailErrors() {
            const errors = []
            if (!this.$v.email.$dirty) return errors;
            !this.$v.email.required && errors.push('E-mail is required.');
            !this.$v.email.email && errors.push('E-mail is not valid.');
            return errors;
        },
        phoneErrors() {
            const errors = []
            if (!this.$v.phone.$dirty) return errors;
            !this.$v.phone.required && errors.push('Phone is required.');
            return errors;
        }
    },
    methods: {
        register: function () {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.validationErrors = true;
            } else {
                this.$store
                    .dispatch("registerUser")
                    .then(response => {
                        if (response) {
                            console.log(response);
                            console.log("Account has been created successfully");
                        }
                    });
            }
        },
        backToStep1: function () {

        }
    }
}
</script>
