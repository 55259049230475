import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueMask from 'v-mask';
import Vuelidate from 'vuelidate';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false

Vue.use(VueMask);
Vue.use(Vuelidate);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
