<template>
<main class="container-full">

    <v-app id="login">
        <v-container>
            <h1 class="text-center">Access your Account</h1>

            <v-alert type="error" :value="isError">
                We were unable to find an account with that mobile phone number or email address. Please retry a different mobile phone number or email address to get your pass code.
            </v-alert>

            <form>
                <v-text-field v-model="emailOrPhone" placeholder="Your email or mobile number">
                    <v-subheader>
                        A code will be send to your selected communication method.
                    </v-subheader>
                </v-text-field>
                <v-subheader class="float-right">
                    A code will be send to your selected communication method.
                </v-subheader>
            </form>

            <v-btn color="primary" class="mr-4" @click="sendOtp">Send Access Code</v-btn>

            <h6 class="text-center">Don't have an account? <a href="/registration">Sign Up</a></h6>
        </v-container>
    </v-app>

</main>
</template>

<script>
export default {
    data: () => {
        return {
            emailOrPhone: "",
            isError: false
        }
    },
    methods: {
        login: function () {
            let emailOrPhone = this.emailOrPhone;

            this.$store.dispatch('requestAuthToken', emailOrPhone).then(() => {
                console.log("Login successfully")
            }).catch((err) => {
                console.log(err);
                // TODO: Handle Error and show error message
            });
        },
        sendOtp: function () {
            let userEntry = this.emailOrPhone;
            userEntry = userEntry.trimEnd();
            this.$store.commit('setEmailPhone', userEntry);

            if (this.emailOrPhone == "") {
                return;
            }

            this.$store
                .dispatch("requestAuthCode")
                .then(response => {
                    if (response) {
                        this.isError = false;
                        console.log(response);
                    } else {
                        this.isError = true;
                    }

                })
                .catch((err) => {
                    console.log(err);
                    this.isError = true;
                });
        }
    }
}
</script>
