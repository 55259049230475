<template>
    <div v-if="loading">
        <v-overlay absolute color="white">
            <div class="loading-wrapper">
                <DotLoader :loaderText="loaderText" />
            </div>
        </v-overlay>
    </div>
    <div v-else>
        <div style="margin: 20px !important; padding-bottom: 1px">
            <v-autocomplete
                :items="products"
                item-value="id"
                item-text="productDescription"
                v-model="selectedProduct"
                label="Select Product"
            ></v-autocomplete>
            <v-btn
            depressed
            color="primary"
            elevation="7"
            large
            block
            style="margin-bottom: 20px !important"
            rounded
            v-on:click="getInventoryData"
            :loading="loadingInventory"
            >Get Inventory Amounts</v-btn>
        </div>
        <v-simple-table v-if="showTable" style="margin-left: 40px !important; margin-right: 40px !important; margin-bottom: 40px !important; padding-bottom: 1px;">
                <template v-slot:default>
                    <thead>
                        <tr>
                        <th class="text-left">
                            Item
                        </th>
                        <th class="text-left">
                            Description
                        </th>
                        <th class="text-left">
                            Available Inventory
                        </th>
                        <th class="text-left">
                            Sold Qty
                        </th>
                        <th class="text-left">
                            Master Order Qty
                        </th>
                        <th class="text-left">
                            Master Order Shipped
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ getItemCode() }}</td>
                            <td>{{ getItemDescription() }}</td>
                            <td>{{ quantityOnHand }}</td>
                            <td>{{ quantityOnSalesOrder }}</td>
                            <td>{{ quantityForMasterOrder }}</td>
                            <td>{{ quantityShippedForMasterOrder }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
    </div>
</template>
<script>
import DotLoader from '../../components/shared/DotLoader.vue';
export default {
    components: {DotLoader},
    data: () => ({
        loading: true,
        loaderText: "Loading Products",
        loadingInventory: false,
        products: null,
        hsDealId: null,
        selectedProduct: null,
        showTable: false,
        quantityOnHand: 0,
        quantityOnSalesOrder: 0,
        quantityForMasterOrder: 0,
        quantityShippedForMasterOrder: 0
    }),
    mounted(){
        let query = {
            get: name => {
                return this.$route.query[name];
            },
            exists: (name, fn, er) => {
                if (this.$route.query[name]) {
                    fn();
                } else if (er) {
                    er();
                }
            },
            set: (name, value, fn) => {
                this.$route.query[name] = value;
                fn();
            }
        };

        query.exists("deal",() => {
            this.hsDealId = query.get("deal");
            this.$store.dispatch("getProducts").then(response => {
                this.products = response;
                this.loading = false;
            });
        });
    },
    methods: {
        getInventoryData(){
            this.loadingInventory = true;
            this.showTable = false;
            let itemCode = this.getItemCode();
            this.$store.dispatch("getProductInventory",itemCode).then((response) => {
                this.quantityOnHand = response.quantityOnHand;
                this.quantityOnSalesOrder = response.quantityOnSalesOrder;
                this.quantityForMasterOrder = response.quantityForMasterOrder;
                this.quantityShippedForMasterOrder = response.quantityShippedForMasterOrder;
                this.showTable = true;
                this.loadingInventory = false;
            })
        },
        findIndexOfItem(){
            return this.products.findIndex(p => p.id === this.selectedProduct);
        },
        getItemCode(){
            let index = this.findIndexOfItem();
            return this.products[index].sageProductCode;
        },
        getItemDescription(){
            let index = this.findIndexOfItem();
            return this.products[index].productDescription;
        }
    }
}
</script>