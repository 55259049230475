<template>
    <div v-if="loading">
        <v-overlay absolute color="white">
            <div class="loading-wrapper">
                <DotLoader :loaderText="this.loaderText" />
            </div>
        </v-overlay>
    </div>
    <div v-else>
        <v-card class="mx-auto" cols="10" style="margin: 20px !important; padding-top: 10px">
            <p class="text-h4 text--primary" style="text-align: center">
                <span>{{this.company.name}}</span>
            </p>
            <p class="text-h6 text--primary" style="text-align: center">
                <span>Price Level: {{getPriceLevelFormatted(this.company.price_level)}}</span>
            </p>
            <div style="margin: 20px !important; padding-bottom: 1px">
                <v-autocomplete
                    :items="products"
                    item-value="id"
                    item-text="productDescription"
                    v-model="selectedProduct"
                    label="Select Product"
                    autocomplete="off"
                ></v-autocomplete>
                <v-text-field 
                    label="Quantity"
                    v-model="quantity"
                ></v-text-field>
                <v-btn
                depressed
                color="primary"
                elevation="7"
                large
                block
                style="margin-bottom: 20px !important"
                rounded
                v-on:click="getPrice"
                >Get Price</v-btn>
            </div>
            <v-simple-table v-if="showTable" style="margin-left: 40px !important; margin-right: 40px !important; margin-bottom: 40px !important; padding-bottom: 1px;">
                <template v-slot:default>
                    <thead>
                        <tr>
                        <th class="text-left">
                            
                        </th>
                        <th class="text-left">
                            Per Item
                        </th>
                        <th class="text-left">
                            Total
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Retail Price</td>
                            <td>${{ formatPrice(retailPrice) }}</td>
                            <td>${{ formatPrice(retailTotal) }}</td>
                        </tr>
                        <tr>
                            <td>Customer's Price</td>
                            <td>${{ formatPrice(discountPrice) }}</td>
                            <td>${{ formatPrice(discountTotal) }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <div v-if="showTable" style="height: 15px;"></div>
            <p v-if="showTable" class="text-h6 text--primary" style="text-align: center">
                <span>Inventory Data</span>
            </p>
            <v-alert
                border="left"
                color="indigo"
                dark
                v-if="showTable && !showInventoryTable"
            >
                Loading Inventory Data...
            </v-alert>
            <v-simple-table v-if="showTable && showInventoryTable" style="margin-left: 40px !important; margin-right: 40px !important; margin-bottom: 40px !important; padding-bottom: 1px;">
                <template v-slot:default>
                    <thead>
                        <tr>
                        <th class="text-left">
                            Item
                        </th>
                        <th class="text-left">
                            Description
                        </th>
                        <th class="text-left">
                            Available Inventory
                        </th>
                        <th class="text-left">
                            Sold Qty
                        </th>
                        <th class="text-left">
                            Master Order Qty
                        </th>
                        <th class="text-left">
                            Master Order Shipped
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ getItemCode() }}</td>
                            <td>{{ getItemDescription() }}</td>
                            <td>{{ quantityOnHand }}</td>
                            <td>{{ quantityOnSalesOrder }}</td>
                            <td>{{ quantityForMasterOrder }}</td>
                            <td>{{ quantityShippedForMasterOrder }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <div style="height: 10px"></div>
        </v-card>
    </div>
</template>
<script>
import DotLoader from '../../components/shared/DotLoader.vue';
export default {
    components: {DotLoader},
    data: () => ({
        loading: true,
        loaderText: "Loading Company & Product Data",
        hsCompanyId: null,
        company: null,
        products: null,
        selectedProduct: null,
        quantity: null,
        showTable: false,
        showInventoryTable: false,
        retailPrice: 0,
        retailTotal: 0,
        discountPrice: 0,
        discountTotal: 0,
        quantityOnHand: 0,
        quantityOnSalesOrder: 0,
        quantityForMasterOrder: 0,
        quantityShippedForMasterOrder: 0
    }),
    mounted(){
        let query = {
            get: name => {
                return this.$route.query[name];
            },
            exists: (name, fn, er) => {
                if (this.$route.query[name]) {
                    fn();
                } else if (er) {
                    er();
                }
            },
            set: (name, value, fn) => {
                this.$route.query[name] = value;
                fn();
            }
        };

        query.exists("company",() => {
            this.hsCompanyId = query.get("company");
            this.$store.dispatch("getCompanyIFrameData",query.get("company")).then(response => {
                this.products = response.products;
                this.company = response.company;
                this.loading = false;
            });
        });
    },
    methods: {
        getPrice(){
            this.showTable = false;
            this.$store.dispatch("getCompanyPrice",{
                selectedProduct: this.selectedProduct,
                quantity: this.quantity,
                priceLevel: this.company.price_level
            }).then(response => {
                this.retailPrice = response.retailPrice;
                this.retailTotal = response.retailPriceTotal;
                this.discountPrice = response.discountPrice;
                this.discountTotal = response.discountPriceTotal;
                this.showInventoryTable = false;
                this.showTable = true;

                let itemCode = this.getItemCode();
                this.$store.dispatch("getProductInventory",itemCode).then((response) => {
                    this.quantityOnHand = response.quantityOnHand;
                    this.quantityOnSalesOrder = response.quantityOnSalesOrder;
                    this.quantityForMasterOrder = response.quantityForMasterOrder;
                    this.quantityShippedForMasterOrder = response.quantityShippedForMasterOrder;
                    this.showInventoryTable = true;
                });
            });
        },
        getPriceLevelFormatted(value){
            var priceLevelFormatted = "";
            switch(value){
                case "1":
                    priceLevelFormatted = "Contractor";
                    break;
                case "2":
                    priceLevelFormatted = "Contractor+";
                    break;
                case "D":
                    priceLevelFormatted = "Special Resale";
                    break;
                case "G":
                    priceLevelFormatted = "Dealer";
                    break;
                case "E":
                    priceLevelFormatted = "Resale";
                    break;
                default:
                    priceLevelFormatted = "Retail";
                    break;
            }
            return priceLevelFormatted;
        },
        formatPrice(value){
            return this.formatNumberWithCommas(this.formatUSDollars(this.formatNumber(value)))
        },
        formatNumber(value){
            return (Math.round(value * 100) / 100).toFixed(2);
        },
        formatUSDollars(value) {
            if (typeof value !== "number") {
                return value;
            }
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0
            });
            return formatter.format(value);
        },
        formatNumberWithCommas(value){
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        findIndexOfItem(){
            return this.products.findIndex(p => p.id === this.selectedProduct);
        },
        getItemCode(){
            let index = this.findIndexOfItem();
            return this.products[index].sageProductCode;
        },
        getItemDescription(){
            let index = this.findIndexOfItem();
            return this.products[index].productDescription;
        }
    }
}
</script>